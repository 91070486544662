import { InstantListening, LoadedInstantListening, LoadingInstantListening } from 'interfaces/mainPage';

import { useMainPageStore } from '.';

const setInstantListening = (instantListening: InstantListening | null) => {
  useMainPageStore.setState({ instantListening });
};

const updateInstantListening = (update: Partial<LoadedInstantListening> | Partial<LoadingInstantListening>) => {
  useMainPageStore.setState(state => {
    if (!state.instantListening) return state;
    return {
      instantListening: {
        ...state.instantListening,
        ...update
      } as InstantListening
    };
  });
};

const setNewlyImportedItemId = (newlyImportedItemId: string | null) => {
  useMainPageStore.setState({ newlyImportedItemId });
};

const setMobileAction = (mobileAction: string) => {
  useMainPageStore.setState({ mobileAction });
};

const setNewFolderParentId = (newFolderParentId: string | undefined) => {
  useMainPageStore.setState({ newFolderParentId });
};

const setIsDragging = (isDragging: boolean) => {
  useMainPageStore.setState({ isDragging });
};

const setActiveDropTargetId = (activeDropTargetId: string | undefined) => {
  useMainPageStore.setState({ activeDropTargetId });
};

const setActiveDragTargetId = (activeDragTargetId: string | undefined) => {
  useMainPageStore.setState({ activeDragTargetId });
};

export const mainPageStoreActions = {
  setInstantListening,
  updateInstantListening,
  setNewlyImportedItemId,
  setMobileAction,
  setNewFolderParentId,
  setIsDragging,
  setActiveDropTargetId,
  setActiveDragTargetId
};
