import type { SpeechifyURI } from '@speechifyinc/multiplatform-sdk';
import { humanFileSize } from 'utils';

import { ErrorSource } from 'config/constants/errors';
import { TextImport, UrlImport } from 'interfaces/import';
import { logError } from 'lib/observability';
import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import { Callback } from 'lib/speechify/adaptors/lib/typeAliases';
import { ListenableContent, getSDK } from 'modules/sdk/lib';
import { getListeningOverhaulImportTask } from 'modules/sdk/lib/facade/listenableContent/factory';
import { ListeningDependenciesFactory } from 'modules/sdk/listeningDependencies';
import { logSegmentEvent } from 'utils/analytics';
import type { Nullable } from 'utils/types';

import { AUTO_IMPORT_LOCAL_MIME_TYPES, AUTO_IMPORT_REMOTE_MIME_TYPES, WebAppImportFlow, WebAppImportType } from '../constants';
import { InstantListeningSupportedData, getRecordType, isTextImport, isUrlImport, matchMimeType } from '../utils';
import { convert } from './convert';
import { isPDF } from './utils';

export type ImportSource = {
  name: string;
  mimeType: string;
  data: string | File | TextImport | UrlImport;
  analyticsProperties?: Record<string, unknown>;
};

export type NonInstantListeningImportSource = ImportSource & {
  // InstantListening data is already handled and shouldn't go to this function.
  data: Exclude<ImportSource['data'], InstantListeningSupportedData>;
};

interface ImportData {
  data: File | string | TextImport | UrlImport;
  name: string;
  mimeType: string;
  source?: string;
  size: number;
  analyticsProperties?: Record<string, unknown>;
  coverImagePath?: string;
}

export type ImportOptions = {
  folderId?: string;
  analyticsProperties?: Record<string, unknown>;
  instantListening: boolean;
  listenableContent?: Nullable<ListenableContent>;
};

export type ImportState = {
  imported: Promise<string>;
  importSource: ImportSource;
  completed: Promise<ListenableContent>;
};

async function getImportSource({ data, ...metadata }: ImportData, shouldConvert: boolean): Promise<ImportSource> {
  if (isTextImport(data)) {
    return { name: data.title, data, mimeType: metadata.mimeType, analyticsProperties: metadata.analyticsProperties };
  }

  if (isUrlImport(data)) {
    return { name: metadata.name || '', data, mimeType: (await isPDF(data.url)) ? 'pdf' : 'web_link' };
  }

  if (!shouldConvert) {
    return { ...metadata, data };
  }

  const conversionResult = await convert(data, metadata.source);

  return {
    ...conversionResult,
    mimeType: conversionResult.mime || metadata.mimeType,
    data: conversionResult.ssl_url,
    name: decodeURIComponent(conversionResult.name)
  };
}

async function startNonInstantListeningImport(listenableContent: ListenableContent) {
  const { bundle: bundleFacade } = await getSDK();

  PSPDFKitFacade.makeTheNextInstanceHeadless();
  await bundleFacade.createBundles(listenableContent, await ListeningDependenciesFactory.singleton.getBundleCreationRequirements());
}

export type ImportMetadata = {
  importType: WebAppImportType;
  importFlow: WebAppImportFlow;
};

export async function importItem(
  { data, ...metadata }: ImportData,
  { folderId: currentFolderId, ...importOptions }: ImportOptions,
  importMetadata: ImportMetadata
): Promise<ImportState> {
  if (metadata.size / 1024 ** 2 > 20) {
    logSegmentEvent('web_app_large_document_imported', {
      size: humanFileSize(metadata.size),
      type: metadata.name.split('.').slice(-1)[0]
    });
  }

  const importMimeTypes = data instanceof File ? AUTO_IMPORT_LOCAL_MIME_TYPES : AUTO_IMPORT_REMOTE_MIME_TYPES;
  const shouldConvert = !importMimeTypes.some(mimeType => matchMimeType(mimeType, metadata.mimeType));
  const importSource = await getImportSource({ data, ...metadata }, shouldConvert);
  const recordType = getRecordType(importSource.mimeType).toLowerCase();

  // If the currentFolderId is 'trash', we should import the item to the root folder
  // because the trash folder is a custom web app folder and not part of the SDK.
  const folderId = currentFolderId === 'trash' ? (await (await getSDK()).library.getRootFolder()).asRaw() : currentFolderId;

  let resolveItemId: (itemId: string) => void;
  let reject: (reason?: unknown) => void;

  const importItemPromise = new Promise<string>((res, rej) => {
    resolveItemId = res;
    reject = rej;
  });

  const handleImportResult: Callback<SpeechifyURI> = importResult => {
    importResult.ifSuccessful(async uri => {
      const eventData: Record<string, string> = {};

      if (isUrlImport(importSource.data)) {
        eventData.type = importSource.mimeType;
        eventData.importedWebLink = importSource.data.url;
        eventData.importedHostName = new URL(importSource.data.url).hostname;
      } else if (isTextImport(data)) {
        eventData.type = importSource.mimeType;
      } else {
        eventData.type = recordType;
      }

      if (metadata.source) {
        eventData.source = metadata.source;
      }

      logSegmentEvent('web_app_document_imported', eventData);
      if (metadata.coverImagePath) {
        // We will move this to handleImport directly when cover image path is supported when initial import
        const sdk = await getSDK();
        sdk.library.updateItem(uri.id, new sdk.sdk.sdkModule.UpdateLibraryItemParams(importSource.name, metadata.coverImagePath, null));
      }
      resolveItemId(uri.id);
    });

    importResult.onFailure(result => {
      logSegmentEvent('web_app_error_importing_document', { type: recordType, error: result.error });
      reject(result.error);
    });
  };

  const handleError = (error: Error) => {
    reject(error);
    logError(error, ErrorSource.FILE_IMPORT);
  };

  const listenableContentPromise = getListeningOverhaulImportTask(
    importSource,
    { folderId, ...importOptions },
    importMetadata,
    handleImportResult,
    handleError
  );

  // during instant listening the upload starts automatically, we need to trigger it manually here for other cases
  if (!importOptions.instantListening) {
    const listenableContent = await listenableContentPromise;
    await startNonInstantListeningImport(listenableContent);
  }

  listenableContentPromise.catch(error => {
    logSegmentEvent('web_app_error_importing_document', { type: recordType, error });
    reject(error);
  });

  return {
    imported: importItemPromise,
    completed: listenableContentPromise,
    importSource
  };
}
