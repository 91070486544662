import { InstantListening } from 'interfaces';

import { createNonPersistentStore } from 'lib/zustand/store';

export type MainPageState = {
  activeDropTargetId: string | undefined;
  activeDragTargetId: string | undefined;
  instantListening: InstantListening | null;
  isDragging: boolean;
  mobileAction: string;
  newFolderParentId: string;
  newlyImportedItemId: string | null;
};

const initialState: MainPageState = {
  activeDropTargetId: undefined,
  activeDragTargetId: undefined,
  instantListening: null,
  isDragging: false,
  mobileAction: '',
  newFolderParentId: '',
  newlyImportedItemId: null
};

export const useMainPageStore = createNonPersistentStore<MainPageState>(() => initialState, {
  isListeningScreenStore: false
});
