import { ImportDialogUsedFrom, ImportSource } from 'interfaces/import';

import { useImportStore } from '.';

export const openImportDialog = (payload?: { source?: ImportSource | null }) => {
  useImportStore.setState({
    isModalOpen: true,
    source: payload?.source ?? null
  });
};

export const closeImportDialog = () => {
  useImportStore.setState({
    isModalOpen: false,
    source: null
  });
};

export const setImportSource = (payload: { source: ImportSource | null; dialogUsedFrom: ImportDialogUsedFrom | null }) => {
  useImportStore.setState({
    source: payload.source,
    dialogUsedFrom: payload.dialogUsedFrom
  });
};

export const importFile = (payload: { id: string; file: File }) => {
  useImportStore.setState(state => ({
    files: { ...state.files, [payload.id]: { state: 'in-progress', file: payload.file } }
  }));
};

export const setImportFileCompleted = (payload: { id: string; itemId: string }) => {
  useImportStore.setState(state => {
    const fileState = state.files[payload.id];
    if (!fileState) return state;

    return {
      files: { ...state.files, [payload.id]: { ...fileState, state: 'completed', itemId: payload.itemId } }
    };
  });
};

export const setImportFileFailed = (id: string) => {
  useImportStore.setState(state => {
    const fileState = state.files[id];
    if (!fileState) return state;

    return {
      files: { ...state.files, [id]: { ...fileState, state: 'failed' } }
    };
  });
};

export const resetImportingFiles = () => {
  useImportStore.setState({ files: {} });
};

export const removeImportFile = async (id: string) => {
  useImportStore.setState(state => {
    const newFiles = { ...state.files };
    delete newFiles[id];
    return { files: newFiles };
  });
};

export const setUpsellModalOpen = (isOpen: boolean) => {
  useImportStore.setState({ isUpsellModalOpen: isOpen });
};

export const importStoreActions = {
  openImportDialog,
  closeImportDialog,
  setImportSource,
  importFile,
  setImportFileCompleted,
  setImportFileFailed,
  resetImportingFiles,
  setUpsellModalOpen
};
