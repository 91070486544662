import { type ImportActions, useImportFile } from 'components/library/import/hooks/useImportFile';
import { createContext, useContext } from 'react';

import type { AddFolderResult } from 'store/library/actions';

interface BackwardCompatibleImportContextValue extends ImportActions {
  currentFolderId: string;
  currentClientSideLibraryItemsCount: number;
  // TODO(albertusdev): Refactor this to reuse the IUser interface once library-v2 has support for fetching IUser without depending on Redux & SDK
  currentUser: { uid?: string } | null | undefined;
  addFolder: (folderName: string) => Promise<AddFolderResult>;
  setFileUploaded: (fileUploaded: boolean) => void;
}

const defaultContextValues: BackwardCompatibleImportContextValue = {
  currentFolderId: '',
  fetchItemsCount: () => Promise.resolve(),
  fetchItem: () => Promise.resolve({ title: '' }),
  currentClientSideLibraryItemsCount: 0,
  currentUser: null,
  addFolder: () => Promise.resolve({ reference: { id: '' }, id: '' } as unknown as AddFolderResult),
  setFileUploaded: () => {}
};

export const BackwardCompatibleImportContext = createContext<BackwardCompatibleImportContextValue>(defaultContextValues);

export const useBackwardCompatibleImportContext = () => {
  return useContext(BackwardCompatibleImportContext) || defaultContextValues;
};

export const useBackwardCompatibleImport = () => {
  const context = useBackwardCompatibleImportContext();

  return {
    ...context,
    importFile: useImportFile({
      currentFolderId: context.currentFolderId,
      actions: {
        fetchItemsCount: context.fetchItemsCount,
        fetchItem: context.fetchItem
      }
    })
  };
};
