import { useCallback, useMemo, useRef } from 'react';

import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';
import { useAuthStore } from 'modules/auth/store/authStore';
import { useBackwardCompatibleImportContext } from 'modules/library/hooks/useBackwardCompatibleImportContext';
import { importStoreActions } from 'modules/library/stores/importStore/actions';
import { getSDK } from 'modules/sdk/lib';
import { canUpgrade } from 'modules/subscription/utils/checkers';
import { showUpsellModal as showContextualUpsellModal } from 'modules/upsells/stores/actions/showUpsellModal';
import { UpsellModalType } from 'modules/upsells/stores/types';

interface UpsellResult {
  currentFileCount: number;
  shouldUpsell: boolean;
}

interface UseFileUpsellReturn {
  checkForUpsell: (fileCount?: number) => Promise<boolean>;
  maxFileCount: number;
}

const MAX_FILE_COUNT = 5;

export function useFileUpsell(): UseFileUpsellReturn {
  const currentUser = useAuthStore(state => state.user);
  const { currentClientSideLibraryItemsCount } = useBackwardCompatibleImportContext();

  const upsellPromise = useMemo<Promise<UpsellResult>>(async () => {
    const { library } = await getSDK();
    if (!currentUser?.uid || !canUpgrade(currentUser)) {
      return { shouldUpsell: false, currentFileCount: Number.MIN_SAFE_INTEGER };
    }

    let recordsCount = 0;
    try {
      recordsCount = await library.fetchTotalNumbersOfRecordItemsOwnedByCurrentUser();
    } catch (err) {
      logError(err as Error, ErrorSource.FILE_UPSELL);
    }
    const currentFileCount = Math.max(recordsCount, currentClientSideLibraryItemsCount);
    return { shouldUpsell: currentFileCount >= MAX_FILE_COUNT, currentFileCount };
  }, [currentUser, currentClientSideLibraryItemsCount]);

  const upsellRef = useRef(upsellPromise);
  upsellRef.current = upsellPromise;

  const checkForUpsell = useCallback(async (fileCount = 1): Promise<boolean> => {
    const { shouldUpsell, currentFileCount } = await upsellRef.current;
    const showUpsellModal = shouldUpsell || currentFileCount + fileCount > MAX_FILE_COUNT;

    if (showUpsellModal) {
      importStoreActions.closeImportDialog();
      showContextualUpsellModal(UpsellModalType.ContextualUnlimitedFiles);
    }

    return showUpsellModal;
  }, []);

  return {
    checkForUpsell,
    maxFileCount: MAX_FILE_COUNT
  };
}
