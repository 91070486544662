import { ImportDialogUsedFrom, ImportSource } from 'interfaces/import';
import { createNonPersistentStore } from 'lib/zustand/store';

type ImportState = 'in-progress' | 'failed' | 'completed';

interface ImportingFile {
  file: File;
  itemId?: string | null;
  state: ImportState;
  progress?: number;
}

export type ImportStoreState = {
  isModalOpen: boolean;
  source: ImportSource | null;
  dialogUsedFrom: ImportDialogUsedFrom | null;
  files: Record<string, ImportingFile>;
  isUpsellModalOpen: boolean;
};

const initialState: ImportStoreState = {
  isModalOpen: false,
  source: null,
  dialogUsedFrom: null,
  files: {},
  isUpsellModalOpen: false
};

export const useImportStore = createNonPersistentStore<ImportStoreState>(() => initialState, {
  isListeningScreenStore: false
});
