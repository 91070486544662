import { IRecord } from 'interfaces';
import { useCallback } from 'react';

import { ErrorSource } from 'config/constants/errors';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { ALLOWED_MIME_TYPES, TextImport, UrlImport } from 'interfaces/import';
import { IntegrationFile } from 'interfaces/integrations';
import * as faro from 'lib/observability';
import { instrumentAction } from 'lib/observability';
import { importStoreActions } from 'modules/library/stores/importStore/actions';
import { ReaderMode, readerStoreActions } from 'modules/listening/features/reader/stores/readerStore';
import { mainPageStoreActions } from 'modules/mainPage/stores/mainPageStore/actions';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { ListenableContent } from 'modules/sdk/lib';
import { createListenableContent } from 'modules/sdk/lib/facade/listenableContent/factory';
import { addToast } from 'modules/toast/stores/actions';
import { logSegmentEvent } from 'utils/analytics';

import { WebAppImportFlow, WebAppImportType } from '../constants';
import { ImportOptions, importItem } from '../upload/import';
import { isCreatingListenableContentSupported, isIntegrationFile, isTextImport, isUrlImport } from '../utils';
import { useFileUpsell } from './useFileUpsell';

export interface ImportInfo {
  fileName: string;
  mimeType: string;
  fileSize: number;
}

function getImportInfo(data: IntegrationFile | File | TextImport | UrlImport): ImportInfo {
  if (isTextImport(data)) {
    return { fileName: data.title, mimeType: 'text', fileSize: 0 };
  }

  if (isUrlImport(data)) {
    return { fileName: '', mimeType: 'web_link', fileSize: 0 };
  }

  if (data instanceof File) {
    return { fileName: data.name, mimeType: data.type, fileSize: data.size };
  }

  const isGoogleDoc = data.fileExtension === 'gdoc';
  const fileName = isGoogleDoc ? `${data.name}.pdf` : data.name;
  const mimeType = isGoogleDoc ? ALLOWED_MIME_TYPES.PDF : data.mimeType;
  const fileSize = data.sizeBytes;

  return { fileName, mimeType, fileSize };
}

export type ImportFileData =
  | {
      data: File | TextImport | UrlImport;
      getFileUrl?: never;
      getFileBlob?: never;
    }
  | {
      data: IntegrationFile;
      getFileUrl: () => Promise<string | null>;
      getFileBlob: (filename: string, mimeType: string) => Promise<File>;
    };

export type ImportFileOptions = ImportFileData & {
  instantListening?: boolean;
  folderId?: string;
  source?: string;
  analyticsProperties?: Record<string, unknown>;
  coverImagePath?: string;
  onConverted?: () => void;
  importType: WebAppImportType;
  importFlow?: WebAppImportFlow;
};

export interface ImportActions {
  fetchItemsCount: () => void;
  fetchItem: (itemId: string) => Promise<{ title: IRecord['title'] }>;
}

export interface ImportFileHookOptions {
  currentFolderId: string;
  actions: ImportActions;
}

const startInstantListening = (listenableContent: ListenableContent) => {
  const readerMode = readerStoreActions.determineReaderMode(listenableContent);

  profilingStoreActions.navigate();
  profilingStoreActions.startMeasurement(
    readerMode === ReaderMode.BOOK ? MeasurementKey.instantListeningBookReaderReady : MeasurementKey.instantListeningClassicReaderReady,
    {}
  );
  mainPageStoreActions.setInstantListening({
    flowStartTimestamp: performance.now(),
    isLoading: false,
    details: { listenableContent }
  });
};

const tryInstantListening = (listenableContent: ListenableContent | null) => {
  if (!listenableContent) return;
  startInstantListening(listenableContent);
};

const getFileData = async (options: ImportFileOptions, importInfo: ImportInfo): Promise<string | File | UrlImport | TextImport> => {
  const { data, getFileUrl, getFileBlob } = options;
  const { fileName, mimeType } = importInfo;
  if (mimeType === ALLOWED_MIME_TYPES.HTML && getFileBlob) {
    return getFileBlob(fileName, mimeType);
  }
  return getFileUrl ? ((await getFileUrl()) ?? (await getFileBlob(fileName, mimeType))) : data;
};

export function useImportFile({ currentFolderId, actions }: ImportFileHookOptions): (options: ImportFileOptions) => Promise<string | null> {
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const { checkForUpsell } = useFileUpsell();
  const folderId = currentFolderId;

  const handleFileImport = useCallback(
    async (options: ImportFileOptions): Promise<string | null> => {
      const { data: importData, source, onConverted, instantListening = true, folderId: overrideFolderId, importType, coverImagePath } = options;

      const importFlow = options.importFlow || WebAppImportFlow.PLUS_BUTTON_MODAL;

      if (await checkForUpsell()) {
        return null;
      }

      const importInfo = getImportInfo(importData);
      const { fileName, mimeType, fileSize } = importInfo;

      try {
        const data = await getFileData(options, importInfo);

        let listenableContent: ListenableContent | null = null;

        const analyticsProperties = options.analyticsProperties ?? {};
        if (isCreatingListenableContentSupported(data)) {
          listenableContent = createListenableContent(data, importType, analyticsProperties, importFlow);
        }
        if (instantListening) {
          mainPageStoreActions.setInstantListening({
            flowStartTimestamp: performance.now(),
            isLoading: true
          });
          tryInstantListening(listenableContent);
        }

        const importOptions: ImportOptions = {
          folderId: overrideFolderId || folderId,
          instantListening,
          analyticsProperties,
          listenableContent: listenableContent
        };

        const importState = await instrumentAction(
          () =>
            importItem({ data, name: fileName, mimeType, source, size: fileSize, analyticsProperties, coverImagePath }, importOptions, {
              importType,
              importFlow
            }),
          'importItem'
        );

        if (!listenableContent) {
          if (importState.importSource.data instanceof File) {
            listenableContent = createListenableContent(importState.importSource.data, importType, analyticsProperties, importFlow);
            if (instantListening) {
              tryInstantListening(listenableContent);
            }
          }

          if (!listenableContent) {
            listenableContent = await importState.completed;
            if (instantListening) {
              startInstantListening(listenableContent);
            }
          }
        }

        importStoreActions.closeImportDialog();

        onConverted?.();

        if (data instanceof File || isIntegrationFile(data)) {
          logSegmentEvent('web_app_file_uploaded', {
            type: fileName.split('.').slice(-1)[0],
            size: fileSize,
            source
          });
        }

        const itemId = await importState.imported;

        if (listenableContent) {
          listenableContent.onItemIdReady(itemId);
        }

        actions.fetchItemsCount();

        if (instantListening) {
          actions.fetchItem(itemId);
          if (!instantListening) {
            navigate(`/item/${itemId}?folder=${folderId}`);
          }
        }

        addToast({
          title: t('Item has been imported successfully')!,
          description: '',
          type: 'success'
        });

        return itemId;
      } catch (e) {
        faro.logError(e as Error, ErrorSource.FILE_IMPORT);

        const isInvalidPasswordError =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ('exception' in (e as any) && ((e as any).exception as { message: string }).message === 'INVALID_PASSWORD') ||
          (e instanceof Error && 'stderr' in e && typeof e.stderr === 'string' && e.stderr.includes('This file requires a password for access'));

        if (isInvalidPasswordError) {
          logSegmentEvent('password-protected-document-fail');
        }

        mainPageStoreActions.setInstantListening(null);

        addToast({
          title: t('Error')!,
          description: t('error_processing_document')!,
          type: 'error'
        });

        return null;
      }
    },
    [actions, folderId, t, checkForUpsell, navigate]
  );

  return handleFileImport;
}
